import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ListItem from "../components/ui/list-item"
import Logo from "../components/images/logo"
import SEO from "../components/seo"

export default ({ data: { allMarkdownRemark } }) => {
  const { edges: faqs } = allMarkdownRemark

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "faqs")
  },[]);



  return (
    <Layout>
      <SEO title="FAQS" />
      <section className="relative pb-20">
        <Link
          to="/"
          className="absolute top-0 z-0 hidden fill-current z-10 md:block lg:ml-32 ml-5"
        >
          <Logo fill="#222b26"></Logo>
        </Link>

        <div
          className="flex justify-center items-center"
          style={{
            height: "50vh",
          }}
        >
          <h1 className="tracking-wide font-light px-6 text-center">
            <em className="font-bradford">Frequently</em> Asked
          </h1>
        </div>

        <ul className="font-light tracking-wide mx-6 lg:px-20">
          {faqs.map(({ node }, index) => {
            const { frontmatter: faq } = node
            if (faq.onFaqPage) {
              return (
                <ListItem
                  key={index}
                  title={faq.question}
                  data={faq.answer}
                ></ListItem>
              )
            }
          })}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetAllFAQSQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "faq" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            question
            answer
            order
            onFaqPage
          }
        }
      }
    }
  }
`
